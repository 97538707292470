@import "partials/font-setup";

@keyframes show-backdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hide-backdrop {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes show-overlay {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.0);
  }
}

@keyframes hide-overlay {
  from {
    transform: scale(1.0);
  }
  to {
    transform: scale(0.5);
  }
}

.backdrop {
  background-color: rgba(black, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  will-change: opacity;
  z-index: 1000;

  &.overlay-backdrop {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.lightbox-backdrop {
    overflow-y: auto;
  }

  &.backdrop-visible {
    animation: show-backdrop 0.2s ease-out;
    visibility: visible;

    .lightbox,
    .overlay {
      animation: show-overlay 0.2s ease-out;
    }
  }

  &.backdrop-hidden {
    animation: hide-backdrop 0.2s ease-out forwards;
    // visibility: visible;
    visibility: hidden;

    .lightbox,
    .overlay {
      animation: hide-overlay 0.2s ease-out;
    }
  }
}

.overlay {
  background-color: white;
  box-sizing: border-box;
  margin: 8px;
  max-width: calc(100% - 16px);
  overflow-y: auto;
  padding: 16px;

  &.fullscreen {
    height: 100%;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  .overlay-title {
    font-family: $acura-bespoke-book;
    font-size: 28px;
  }

  .overlay-close-button {
    border: 1px #000 solid;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    height: 24px;
    position: relative;
    width: 24px;
    transform: rotate(45deg);

    &:after,
    &:before {
      background-color: black;
      content: '';
      position: absolute;
    }

    &:after {
      height: 12px;
      width: 1px;
      transform: translate(10.5px, 5px);
    }

    &:before {
      height: 1px;
      width: 12px;
      transform: translate(5px, 10.5px);
    }
  }

  .overlay-body {
    &:not(:first-child) {
      padding-top: 16px;
    }
  }
}

.lightbox {
  display: table;
  height: 100%;
  width: 100%;

  .lightbox-cell {
    display: table-cell;
    vertical-align: middle;
    position: relative;

    .overlay-close-button {
      border: 1px #fff solid;
      border-radius: 50%;
      box-sizing: border-box;
      cursor: pointer;
      height: 24px;
      width: 24px;
      transform: rotate(45deg);
      position: absolute;
      /*top: 2px;*/
      right: 2%;
      z-index: 1002;

      @include respond-to(sm) {
        right: 6%;
      }
      @include respond-to(md) {
        right: 7%;
      }
      @include respond-to(lg) {
        right: 8%;
      }

      &:after,
      &:before {
        background-color: white;
        content: '';
        position: absolute;
      }

      &:after {
        height: 12px;
        width: 1px;
        transform: translate(10.5px, 5px);
      }

      &:before {
        height: 1px;
        width: 12px;
        transform: translate(5px, 10.5px);
      }
    }
  }

  .media-content {
    box-sizing: border-box;
    margin: auto;
    position: relative;
    width: 80%;

    > * {
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
